<template>
    <b-modal :id="modalId" hide-footer size="xl">
      <div class="AllContent">
        <div class="flexHeaderComunidade">
          <div>
            <div class="comunidadeTitle">{{ title }}</div>
            <div class="comunidadeSubtitle">{{ subtitle }}</div>
          </div>
        </div>
  
        <div class="tableModule">
          <div class="body">
            <h3 class="instruTitle">{{ instructionsTitle }}</h3>
            <slot></slot>

            <div role="group" class="form-group formGpComunidade">
              <label for="" class="d-block">{{ inputLabel }}</label>
              <div>
                <input type="text" :disabled="disabled" placeholder="Cole aqui" v-model="url" @input="atualizarURL" class="form-control">
              </div>
            </div>
  
            <div>
              <button :disabled="disabled" class="btnSalvarComunidade" @click="saveComunidade">
                {{ saveButtonLabel }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </template>
  
  <script>
  import CourseService from "@/services/resources/CourseService";
  import notify from "@/services/libs/notificacao";
  import iconError from "@/assets/icons/error_comunidade.svg"
  import iconSuccess from "@/assets/icons/success_comunidade.svg"
const serviceCourse = CourseService.build();
  export default {
    props: {
      modalId: String,
      title: String,
      subtitle: String,
      instructionsTitle: String,
      instructions: Array,
      comunidadeURL: String,
      inputLabel: String,
      saveButtonLabel: String,
      class_id: Number,
      comunidadeName: String,
      course_id: String
    },
    data() {
      return {
        url: this.comunidadeURL,
        disabled: true,
        urlRules:[
          { circle: { regex: /circle/i} },
          { discord: { regex: /discord/i}},
          { slack: { regex: /slack/i}}
        ],
        regex: /[^a-zA-Z0-9\\.\\-\\_\\@\\#\\$\\%\\^\&\\*\\(\\)\\,\\;\\:\\'\\\"\\/\\?\\~\\!]/g
      }
    },
    methods: {
      atualizarURL() {
        const regexEmojis = /[^a-zA-Z0-9\\.\\_\\@\\`\\´\\{\\}\\<\\>\\¨\\+\\-\\=\\#\\$\\%\\^\&\\*\\(\\)\\,\\;\\:\\'\\\"\\/\\?\\~\\!\\[\\\]\\]/g;
        let url = this.url;
        url = this.url.replace(regexEmojis, '');
        this.url = url;
        this.$emit('update:comunidadeURL', this.url);
      },
      saveComunidade(){
        if(this.validaCampo()){
          let data = {
            name: this.comunidadeName,
            url: this.url,
            class_id: this.class_id
          };
      
          this.$root.$emit("loadOn");
          serviceCourse
            .post("/"+this.course_id+"/class/community",data)
            .then((resp) => {
              this.$root.$emit("loadOff");
              if(resp.message){
                notify("sucesso", resp.message,iconSuccess);
              }else if(resp){
                notify("sucesso", "Comunidade configurada",iconSuccess);
              }
            })
            .catch((err) => {
              console.log(err);
              notify("erro", "Não foi possível configurar a comunidade!");
              this.$root.$emit("loadOff");
            });
        }
      },
      validaCampo(){
        if(this.url.length >500){
          notify("erro", "Limite de caracteres excedido", iconError);
          return false
        }

        if(this.url.length === 0){
          return true;
        }

        for (const rules of this.urlRules) {
            if (rules.hasOwnProperty(this.comunidadeName.toLowerCase())) {
              const validRule = rules[this.comunidadeName.toLowerCase()].regex;
              var valid = validRule.test(this.url)
              if(!valid){
                notify("erro", "Insira a URL da comunidade", iconError);
                return false
              }
            }
          }
          return true;
      },
      getComunidade(option) {
        this.reset()
        this.$root.$emit("loadOn");

        let data = {
          type: option,
          class_id: this.class_id
        };
        serviceCourse
          .post("/"+this.course_id+"/class/community/get",data)
          .then((resp) => {
            this.disabled = false
            this.url = resp.json
            this.$root.$emit("loadOff");
          })
          .catch((err) => {
            this.$root.$emit("loadOff");
          });
      },
      reset() {
        this.url = ""
        this.disabled = true
      }
    }
  };
  </script>

  <style lang="scss">

    .modal-content .modal-header{
      border-bottom: none;
    }
    #modal-comunidade {
        .flexHeaderComunidade{
            border-bottom: 1px solid #CBCBCB;
            margin-bottom: 20px;
        }
        .btnSalvarComunidade{
            border-radius: 8px;
            background: var(--maincolor);
            font-weight: 600;
            font-size: 14px;
            padding: 12px 36px;
            line-height: 16.8px;
            color: var(--buttonTextColor);
        }
        .formGpComunidade{
            margin-top: 30px;
            margin-bottom: 20px;
        }
        .formGpComunidade input{
          padding: 20px 40px 20px 40px;
          height: 52px;
        }

        .formGpComunidade input::placeholder{
            margin-left: 30px;
            color: #6E6E71;
            font-weight: 400;
            font-size: 14px;
            line-height: 16.8px;
        }
        .AllContent {
            .comunidadeLink{
            color: #FE8700;
            text-decoration: underline;
            }
            .instruTitle{
            font-size: 14px;
            font-weight: 600;
            line-height: 16.8px;
            color: var(--fontcolor);
            }
            .instruItem{
            font-size: 14px;
            font-weight: 500;
            line-height: 28px;
            color: var(--fontcolor);
            }
            .comunidadeTitle{
            font-family: Inter;
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            letter-spacing: 0.2px;
            line-height: 21.6px;
            color: var(--fontcolor);
            margin-bottom: 15px;
            }
            .comunidadeSubtitle {
            font-family: Inter;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16.8px;
            letter-spacing: 0.2px;
            margin-bottom: 15px;
            color: #6E6E71;
            }
        }
    }
</style>