<template>
  <b-modal id="modal-telegram-liberation" hide-footer size="xl">
    <div class="AllContent">
      <div class="flexHeader">
        <div>
          <div class="title">Integração com o Telegram</div>
          <div class="subtitle">Configure suas liberações</div>
          <button
            v-if="showBtnTelegram && !AllTelegramIntegrations.length"
            @click="initTelegramIntegration"
            class="btn-criar"
          >
            Iniciar integração
          </button>
        </div>
      </div>

      <div
        v-if="
          AllTelegramIntegrations.filter(i => i.status === 'draft' && !i.rule)
            .length >= 1
        "
      >
        <div class="body" style="display: flex; flex-direction: column">
          <h3 class="title mt-3">Instruções para continuar a integração:</h3>
          <h4 class="subtitle mt-2">1. No Telegram, crie um grupo ou canal.</h4>
          <h4 class="subtitle mt-2">
            2. É necessário que o grupo ou canal esteja como privado.
          </h4>
          <h4 class="subtitle mt-2">
            3. Adicione o membro <b>voomp_bot</b> ao seu grupo ou canal, com
            permissão de admin
          </h4>
          <h4 class="subtitle mt-2">
            4. Envie o código "{{
              AllTelegramIntegrations.filter(i => i.status === 'draft')[0]
                .code_integration
            }}" no grupo ou canal para confirmar a integração.
          </h4>
          <h4 class="subtitle mt-2">
            5. Defina abaixo como e quando os alunos dessa turma serão
            adicionados ao seu grupo ou canal.
          </h4>
          <h4 class="subtitle mt-2">
            6. Após o código ser enviado no grupo ou canal, atualize esta página
            para verificar o status da integração.
          </h4>

          <div class="space animationOpacity2">
            <b-form-group label="Tipo de Liberação" label-for="name-module">
              <b-form-select
                v-model="selectedType"
                :options="optionsType"
              ></b-form-select>
            </b-form-group>
          </div>
          <div
            class="space animationOpacity2"
            v-if="selectedType === 'daysAfterInscription'"
          >
            <b-form-group label="Data de Liberação" label-for="name-module">
              <b-form-input
                v-mask="'####'"
                v-model="daysAfterInscription"
                placeholder="Ex: 15 Dias"
              ></b-form-input>
            </b-form-group>
          </div>
          <div
            class="space animationOpacity2"
            v-if="selectedType === 'daysAfterInscription'"
          >
            <b-form-group
              label="Data de Finalização(Opcional)"
              label-for="name-module"
            >
              <b-form-input
                v-mask="'####'"
                v-model="daysAfterInscriptionEnd"
                placeholder="Em dias ou em branco para duração indefinida"
              ></b-form-input>
            </b-form-group>
          </div>
          <div
            class="space animationOpacity2"
            v-if="selectedType === 'fixedDate'"
          >
            <b-form-group
              label="Data e hora de liberação"
              label-for="name-module"
            >
              <b-form-input
                v-mask="'##/##/#### ##:##'"
                v-model="localDataInit"
                placeholder="Ex: 15/04/2021 15:45"
              ></b-form-input>
            </b-form-group>
          </div>
          <div
            class="space animationOpacity2"
            v-if="selectedType === 'fixedDate'"
          >
            <b-form-group
              label="Data e hora de encerramento"
              label-for="name-module"
            >
              <b-form-input
                v-mask="'##/##/#### ##:##'"
                v-model="localDataFim"
                placeholder="Ou em branco para duração indefinida"
              ></b-form-input>
            </b-form-group>
          </div>

          <button @click="salvarTelegramEdit" class="mt-2 btn-criar">
            Finalizar integração
          </button>
        </div>
      </div>
      <div
        v-if="
          AllTelegramIntegrations.filter(i => i.status === 'published')
            .length >= 1
        "
        class="tableModule"
      >
        <div class="header">
          <div>Nome</div>
          <div>Tipo</div>
          <div>Liberação</div>
          <div>Encerramento</div>
          <div>Ação</div>
        </div>
        <div
          class="body"
          v-for="telegram in AllTelegramIntegrations"
          :key="telegram.id"
        >
          <div class="text">{{ telegram.name }}</div>

          <div class="text" v-if="telegram.rule === null">Liberado</div>
          <div class="text" v-else-if="telegram.rule === 'dinamic'">
            Por prazo
          </div>
          <div class="text" v-else-if="telegram.rule === 'fixed'">Por data</div>
          <div class="text" v-else>Liberado</div>

          <div class="text" v-if="telegram.rule === null">Liberado</div>
          <div class="text" v-else-if="telegram.rule === 'dinamic'">
            {{ telegram.dinamic_date.daysToStart }} Dias
          </div>
          <div class="text" v-else-if="telegram.rule === 'fixed'">
            {{
              telegram.dinamic_date.fixedDateStart | moment('DD/MM/YYYY HH:mm')
            }}
          </div>
          <div class="text" v-else>Liberado</div>

          <div class="text" v-if="telegram.rule === null">Liberado</div>
          <div
            class="text"
            v-else-if="
              telegram.rule === 'dinamic' &&
              telegram.dinamic_date.daysToEnd &&
              telegram.dinamic_date.daysToEnd === 0
            "
          >
            {{
              telegram.dinamic_date.daysToEnd
                ? telegram.dinamic_date.daysToEnd + ' Dias'
                : 'Duração indefinida'
            }}
          </div>
          <div class="text" v-else-if="telegram.rule === 'dinamic'">
            {{
              telegram.dinamic_date.daysToEnd
                ? telegram.dinamic_date.daysToEnd + ' Dias'
                : 'Duração indefinida'
            }}
          </div>
          <div class="text" v-else-if="telegram.rule === 'fixed'">
            {{
              telegram.dinamic_date.fixedDateEnd
                ? formatDateTime(telegram.dinamic_date.fixedDateEnd)
                : 'Duração indefinida'
            }}
          </div>
          <div class="text" v-else>Liberado</div>

          <div class="editar" @click="removeTelegramIntegration(telegram)">
            Remover
          </div>
        </div>
      </div>
      <div
        v-if="
          AllTelegramIntegrations.filter(i => i.status === 'draft' && i.rule)
            .length >= 1
        "
        class="tableModule"
      >
        <div class="body" style="display: flex; flex-direction: column">
          <h3 class="title mt-3">
            Há uma integração em andamento, caso esteja demorando muito,
            verifique se você executou todos os passos abaixo:
          </h3>
          <h4 class="subtitle mt-2">1. No Telegram, crie um grupo ou canal.</h4>
          <h4 class="subtitle mt-2">
            2. É necessário que o grupo ou canal esteja como privado.
          </h4>
          <h4 class="subtitle mt-2">
            3. Adicione o membro <b>voomp_bot</b> ao seu grupo ou canal, com
            permissão de admin
          </h4>
          <h4 class="subtitle mt-2">
            4. Envie o código "{{
              AllTelegramIntegrations.filter(i => i.status === 'draft')[0]
                .code_integration
            }}" no grupo ou canal para confirmar a integração.
          </h4>
          <h4 class="subtitle mt-2">
            5. Defina abaixo como e quando os alunos dessa turma serão
            adicionados ao seu grupo ou canal.
          </h4>
          <h4 class="subtitle mt-2">
            6. Após o código ser enviado no grupo ou canal, atualize esta página
            para verificar o status da integração.
          </h4>

          <div
            class="editar mt-3"
            @click="
              removeTelegramIntegration(
                AllTelegramIntegrations.filter(
                  i => i.status === 'draft' && i.rule
                )[0],
                true
              )
            "
          >
            Cancelar integração
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    AllTelegramIntegrations: Array,
    showBtnTelegram: Boolean,
    initTelegramIntegration: Function,
    removeTelegramIntegration: Function,
    salvarTelegramEdit: Function,
    selectedType: String,
    daysAfterInscription: String,
    daysAfterInscriptionEnd: String,
    dataInit: String,
    dataFim: String,
    optionsType: Array
  },
  computed: {
    localDataInit: {
      get() {
        return this.dateInit
      },
      set(val) {
        this.$emit('update:dataInit', val)
      }
    },
    localDataFim: {
      get() {
        return this.dateFim
      },
      set(val) {
        this.$emit('update:dataFim', val)
      }
    }
  },
  methods: {
    formatDateTime(dateTime) {
      return moment(dateTime).format('DD/MM/YYYY HH:mm')
    }
  }
}
</script>

<style scoped lang="scss">
#modal-telegram-liberation {
  header {
    border: none !important;
  }
  .fundoModalModuleLiberation {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: var(--fundomodal);
    z-index: 999;
    .modalModuleLiberation {
      position: fixed;
      z-index: 9999;
      background-color: var(--backgroundcolor);
      top: 0;
      right: 0;
      height: 100%;
      width: 450px;
      padding: 50px;
      border-radius: 5px 0px 0px 5px;
      .close {
        cursor: pointer;
      }
      .title {
        font-family: Inter;
        font-style: normal;
        font-weight: 800;
        font-size: 18px;
        color: var(--fontcolor) !important;
      }
      .space {
        margin-top: 35px;
        input {
          border: 1px solid var(--bordercolor);
          box-sizing: border-box;
          border-radius: 5px;
          height: 40px;
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #333;
          overflow: hidden !important;
          resize: none !important;
          background: white;
        }
        input:hover,
        input:focus {
          border: 1px solid var(--maincolor);
          box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #000000;
        }
        label {
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          color: var(--fontcolor);
        }
        select {
          border: 1px solid var(--bordercolor);
          box-sizing: border-box;
          border-radius: 5px;
          height: 45px;
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #6d767e;
          overflow: hidden !important;
          resize: none !important;
        }
        .btn-salvar {
          background: var(--maincolor);
          border-radius: 5px;
          border: none;
          width: 146px;
          font-family: Inter;
          color: #ffffff;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          height: 40px;
        }
      }
    }
  }
  .AllContent {
    padding: 0 30px 30px 30px;
    .totalModules {
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: #81858e;
      margin-top: 30px;
    }
    .title {
      font-family: Inter;
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      letter-spacing: 0.2px;
      color: var(--fontcolor);
      margin-bottom: 5px;
    }
    .subtitle {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      letter-spacing: 0.2px;
      color: #81858e;
    }
    .flexHeader {
      display: flex;
      justify-content: space-between;
      border-bottom: 0.5px solid #ededf0;
      padding-bottom: 30px;
    }
    .flexHeader2 {
      display: flex;
      justify-content: space-between;
      padding-bottom: 30px;
    }
    .tableModule {
      width: 100%;
      height: 41vh;
      overflow: auto;
      margin-top: 30px;
      .header {
        display: grid;
        grid-template-columns: 3fr 2fr 2fr 2fr 1fr;
        margin-bottom: 20px;
        div {
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          letter-spacing: 0.2px;
          color: var(--fontcolor);
        }
      }
      .body {
        display: grid;
        grid-template-columns: 3fr 2fr 2fr 2fr 1fr;
        border-bottom: 0.5px solid #ededf0;
        height: 40px;
        margin: 15px 5px;
        .text {
          font-family: Inter;
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          letter-spacing: 0.2px;
          color: #81858e;
        }
        .editar {
          font-family: Inter;
          font-style: normal;
          font-weight: 600;
          font-size: 13px;
          letter-spacing: 0.2px;
          color: var(--maincolor);
          cursor: pointer;
        }
      }
    }
  }
}
</style>
